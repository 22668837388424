export enum COLORS {
  GREY_BG = 'rgb(242, 242, 242)',
  GREY_BG_LIGHT = 'rgb(249,249,249)',
  GREY_DARK = '#888B8D',
  SECONDARY = '#e6e6e6',
  PRIMARY = '#F84300',
  PRIMARY_LIGHT = '#ffe9e0',
  PRIMARY_LIGHT_2 = '#fff8f5',
  RED = '#E53935',
  WHITE = '#fff',
  GREEN = '#4caf50',
  BLUE = '#3f51b5',
  GREY_LIGHT = '#F5F5F5',
  GREY = '#E4E4E4',
  BLACK_LIGHT = '#222222',
  BLACK = '#000000',
  ERROR = '#f44336',
}

export type ColorsType = {
  [key in keyof typeof COLORS]: (typeof COLORS)[key]
}
